// import MyTasks from "~/src/components/MyTasks/MyTasks";
import axiosClient from "./request";
// export const API_URL =
//   "https://kfib5u605h.execute-api.ap-south-1.amazonaws.com/dev/"; //uat url for Ds domain
export const API_URL =
  "https://qeidryrjjd.execute-api.ap-south-1.amazonaws.com/prod/"; // prod url for Ds domain
// export const API_URL = "http://192.168.10.93:3000/dev/";

export function getTaskList(params) {
  return axiosClient.get(`${API_URL}task/getalltask`, { params });
}

export function getAllComments(params) {
  return axiosClient.get(`${API_URL}comments/get_all_comments`, { params });
}

export function postAComment(data) {
  return axiosClient.post(`${API_URL}comments/create_comments`, data);
}

export function getTimeLogData(params) {
  return axiosClient.get(`${API_URL}timelog/get-timelog`, {
    params,
  });
}

export function postTimeLogData(data) {
  return axiosClient.post(`${API_URL}timelog/create-timelog`, data);
}

export function updateTaskStatusCycle(data) {
  return axiosClient.post(`${API_URL}task/task-cycle-update`, data);
}

export function getTaskDetails(params) {
  return axiosClient.get(`${API_URL}task/taskById`, {
    params,
  });
}

export function getStreamPriorityFilterDetails(params) {
  return axiosClient.get(`${API_URL}task/getalltask`, {
    params,
  });
}

export function getStreamsData(userId, myTask) {
  return axiosClient.get(
    `${API_URL}task/stream?userId=${userId}&myTask=${myTask}`
  );
}

export function updateETADate(data) {
  return axiosClient.put(`${API_URL}task/task_update`, data);
}

export async function deleteTask(taskId, setReload) {
  try {
    await axiosClient.delete(`${API_URL}task/deletetask?task_id=${taskId}`);
    setReload((prevReload) => !prevReload);
  } catch (error) {
    console.error("Error deleting task", error);
  }
}

export function updateTaskStatus(data) {
  return axiosClient.post(`${API_URL}task/task-cycle-update`, data);
}

export function getDashboardCardsCount(userId) {
  return axiosClient.get(
    `${API_URL}task/getDashbordCardsCount?user_id=${userId}`
  );
}

export function getDashbordUpcomingTasks(userId) {
  return axiosClient.get(
    `${API_URL}task/getDashboardUpcomingTasks?user_id=${userId}`
  );
}

export function getDashbordStreamReport(userId) {
  return axiosClient.get(
    `${API_URL}task/getDashbordStreamReport?user_id=${userId}`
  );
}

export function getDashboardStreamTimeReport(userId) {
  return axiosClient.get(
    `${API_URL}task/getDashboardStreamTimeReport?user_id=${userId}`
  );
}
